import * as bootstrap from 'bootstrap';
import Aos from 'aos';

Aos.init({
  once: true,
});

particlesJS.load('header-particles', 'particles.json', function() {
  console.log('callback - particles.js config loaded');
});
